import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import Compressor from "compressorjs";
import {
  WithLoader,
  WeekOpeningHours,
  Row,
  Column,
  ImageUpload,
} from "components/common";
import {
  fetchRestaurant,
  updateOpeningHours,
  updateRestaurant,
} from "services/restaurants";
import * as RestaurantService from "services/restaurants";
import { useInput, useToggle } from "resources/hooks";
import { formatToUppercase } from "resources/helpers";
import { useRequest } from "resources/hooks";
import "styles/Restaurants.scss";

const RestaurantUpdate = ({ routes, location, history, match }) => {
  const { restaurantId } = match.params;
  const [loading, setLoading] = useState(true);
  const label = useInput("");
  const phone = useInput("");
  const address = useInput("");
  const city = useInput("");
  const corporateName = useInput("");
  const siretNumber = useInput("");
  const benefitPercentage = useInput(0, { type: "number", min: 0 });
  const invoiceNumberOffset = useInput(1, { type: "number", min: 1 });
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  const active = useToggle(false);
  const archived = useToggle(false);
  const defaultReportDisplay = useToggle(false);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openingHours, setOpeningHours] = useState(null);
  const inputs = [label, phone, address, city];

  const getAvailableRestaurantCategories = useRequest({
    service: RestaurantService.getAvailableRestaurantCategories,
    requestOnInit: true,
  });

  useEffect(() => {
    if (getAvailableRestaurantCategories.response) {
      setAvailableCategories(
        getAvailableRestaurantCategories.response.map((category) => ({
          key: category.label,
          text: category.label,
          value: category.label,
        }))
      );
    }
  }, [getAvailableRestaurantCategories.response]);

  const initiateRestaurant = async () => {
    try {
      setLoading(true);

      const result = await fetchRestaurant({ restaurantId });
      label.setValue(result.label);
      phone.setValue(result.phone);
      address.setValue(result.address);
      city.setValue(result.city);
      corporateName.setValue(result.corporateName);
      siretNumber.setValue(result.siretNumber);
      benefitPercentage.setValue(result.benefitPercentage);
      invoiceNumberOffset.setValue(result.invoiceNumberOffset);
      defaultReportDisplay.setValue(result.defaultReportDisplay);
      active.setValue(result.active);
      archived.setValue(result.archived);
      setOpeningHours(result.openingHours);
      setSelectedCategories(
        result.categories.map((category) => category.label)
      );

      setImage(result.publicUrl);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (image) => {
    setImageError(null);
    new Compressor(image, {
      quality: 0.8,
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: Infinity, // disable .png to .jpg conversion to keep image transparency
      success(result) {
        setImage(result);
      },
      error(err) {
        console.error(err.message);
      },
    });
  };

  const handleUpdate = async () => {
    let hasErrors = false;
    inputs.forEach((input) => {
      if (input.value === "") {
        input.setError({
          content: "Obligatoire",
        });
        hasErrors = true;
      }
    });

    if (!image) {
      setImageError({
        content: "Obligatoire",
      });
      hasErrors = true;
    }

    if (hasErrors) return;

    try {
      setLoading(true);

      await updateRestaurant({
        restaurantId,
        label: label.value,
        phone: phone.value,
        address: address.value,
        city: city.value,
        corporateName: corporateName.value,
        siretNumber: siretNumber.value,
        benefitPercentage: benefitPercentage.value,
        invoiceNumberOffset: invoiceNumberOffset.value,
        defaultReportDisplay: defaultReportDisplay.value,
        active: active.value,
        archived: archived.value,
        image: typeof image === "object" ? image : undefined,
        categories: selectedCategories,
      });

      await updateOpeningHours({
        restaurantId,
        openingHours: {
          MONDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "MONDAY"
          ),
          TUESDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "TUESDAY"
          ),
          WEDNESDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "WEDNESDAY"
          ),
          THURSDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "THURSDAY"
          ),
          FRIDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "FRIDAY"
          ),
          SATURDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "SATURDAY"
          ),
          SUNDAY: openingHours.filter(
            (openingHour) => openingHour.weekday === "SUNDAY"
          ),
        },
      });

      history.push("/restaurants");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantId) initiateRestaurant();
  }, []);

  return (
    <WithLoader loading={loading} text="Chargement du restaurant">
      <div className="restaurant-creation data-container">
        <Row childrenMargin="small">
          <Column style={{ flexBasis: "45%" }}>
            <ImageUpload
              image={image}
              handleImageUpload={handleImageUpload}
              error={!!imageError}
            />
          </Column>

          <Column style={{ flexBasis: "55%" }}>
            <Form>
              <Form.Group className="form-row">
                <Form.Field width={8}>
                  <Form.Input label="Nom de l'établissement" {...label.props} />
                </Form.Field>

                <Form.Field>
                  <label>Actif</label>
                  <Form.Checkbox toggle {...active.props} />
                </Form.Field>

                <Form.Field>
                  <label>Caché</label>
                  <Form.Checkbox toggle {...archived.props} />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={8}>
                  <Form.Input label="Adresse" {...address.props} />
                </Form.Field>

                <Form.Field width={8}>
                  <Form.Input label="Ville" {...city.props} />
                </Form.Field>

                <Form.Field width={8}>
                  <Form.Input label="Téléphone" {...phone.props} />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={16}>
                  <label>Catégories d'établissement</label>
                  <Form.Dropdown
                    options={availableCategories}
                    placeholder="Asiatique, Burger, Tacos..."
                    search
                    selection
                    multiple
                    fluid
                    allowAdditions
                    additionLabel="Ajouter "
                    value={selectedCategories}
                    noResultsMessage="Ajoutez de nouvelles catégories"
                    onAddItem={(e, { value }) => {
                      const formattedValue = formatToUppercase(value);
                      setSelectedCategories(
                        Array.from(
                          new Set([...selectedCategories, formattedValue])
                        )
                      );

                      if (
                        !availableCategories.some(
                          (availableCategory) =>
                            availableCategory.value === formattedValue
                        )
                      )
                        setAvailableCategories([
                          ...availableCategories,
                          {
                            key: formattedValue,
                            text: formattedValue,
                            value: formattedValue,
                          },
                        ]);
                    }}
                    onChange={(e, { value }) => {
                      setSelectedCategories(value);
                    }}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={11}>
                  <Form.Input
                    label="Nom de l'entreprise"
                    {...corporateName.props}
                  />
                </Form.Field>

                <Form.Field width={5}>
                  <Form.Input
                    label="Part restaurant (%)"
                    {...benefitPercentage.props}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={6}>
                  <Form.Input label="SIRET" {...siretNumber.props} />
                </Form.Field>

                <Form.Field width={5}>
                  <Form.Input
                    label="N° départ facture"
                    {...invoiceNumberOffset.props}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Affiché dans les rapports</label>
                  <Form.Checkbox toggle {...defaultReportDisplay.props} />
                </Form.Field>
              </Form.Group>

              <Form.Group className="form-row">
                <Form.Field width={6}>
                  <label>Horaires d'ouverture</label>
                  <WeekOpeningHours
                    openingHours={openingHours}
                    handleUpdate={(value) => {
                      setOpeningHours(value);
                    }}
                  />
                </Form.Field>
              </Form.Group>

              <Button
                onClick={handleUpdate}
                loading={loading}
                disabled={loading}
                color="blue"
              >
                Mettre à jour
              </Button>
            </Form>
          </Column>
        </Row>
      </div>
    </WithLoader>
  );
};

export default withRouter(RestaurantUpdate);
