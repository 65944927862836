import { useEffect } from "react";
import { Button, Header, Label, Table } from "semantic-ui-react";
import { useRequest } from "../../resources/hooks";
import * as DiscountCodeService from "../../services/discountCodes";
import { NoDataMessage, Row, WithLoader } from "../common";
import useModal from "../../resources/hooks/useModal";
import DiscountCodeModal from "./DiscountCodeModal";
import "../../styles/DiscountCodes.scss";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Pagination } from "semantic-ui-react";
import usePagination from "../../resources/hooks/usePagination";
import { centimesToAmount } from "../../resources/helpers/PriceHelper";
import { fetchRestaurants } from "../../services/restaurants";
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);

const DiscountCodesList = () => {
  const getDiscountCodesPaginated = useRequest({
    service: DiscountCodeService.getDiscountCodesPaginated,
  });
  const restaurantsList = useRequest({
    service: fetchRestaurants,
    requestOnInit: true,
  });

  const { currentPage, handlePageChange, MAX_ROWS_BY_PAGE } = usePagination({
    request: getDiscountCodesPaginated,
  });

  const maxPage =
    Math.ceil(getDiscountCodesPaginated.response?.count / MAX_ROWS_BY_PAGE) ||
    1;

  const {
    openModal,
    setUpdateId,
    updateId: discountCodeId,
    isModalOpened,
    closeModal,
  } = useModal(DiscountCodeModal, getDiscountCodesPaginated);
  const discountCodes = getDiscountCodesPaginated.response?.discountCodes || [];

  useEffect(() => {
    handlePageChange(1);
  }, []);

  return (
    <div id="discount-codes">
      <Row className="header" align="center" fluid>
        <Header as="h2">Codes de réduction</Header>

        <Button
          icon="plus"
          color="blue"
          content="Ajouter une réduction"
          style={{ marginLeft: "1rem" }}
          onClick={() => openModal()}
        />
      </Row>

      <WithLoader
        loading={getDiscountCodesPaginated.loading}
        text="Récupération des promotions"
      >
        <div className="data-container">
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Actif ?</Table.HeaderCell>
                <Table.HeaderCell>Expiration</Table.HeaderCell>
                <Table.HeaderCell>Nombre d'applications</Table.HeaderCell>
                <Table.HeaderCell>Réduction</Table.HeaderCell>
                <Table.HeaderCell>Restaurant applicable</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {discountCodes.map((discountCode) => (
                <Table.Row key={discountCode.id}>
                  <Table.Cell>{discountCode.code}</Table.Cell>

                  <Table.Cell>{discountCode.isActive ? "✅" : "❌"}</Table.Cell>

                  <Table.Cell>
                    {discountCode.expirationDate ? (
                      dayjs().isSameOrBefore(
                        discountCode.expirationDate,
                        "d"
                      ) ? (
                        dayjs().locale("fr").to(discountCode.expirationDate)
                      ) : (
                        <NoDataMessage>
                          Expiré{" "}
                          {dayjs().locale("fr").to(discountCode.expirationDate)}
                        </NoDataMessage>
                      )
                    ) : (
                      <NoDataMessage>Sans limite de durée</NoDataMessage>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    {discountCode.maximumApplications || (
                      <NoDataMessage>Illimité</NoDataMessage>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    {discountCode.percentageAmount ? (
                      <Label color="brown">{`-${discountCode.percentageAmount}%`}</Label>
                    ) : discountCode.fixedAmount ? (
                      <Label color="purple">{`${-centimesToAmount(
                        discountCode.fixedAmount
                      )}€`}</Label>
                    ) : (
                      <NoDataMessage>N/A</NoDataMessage>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    {discountCode.applicableRestaurantId &&
                    restaurantsList.response?.some(
                      (restaurant) =>
                        restaurant.id === discountCode.applicableRestaurantId
                    ) ? (
                      <Label>
                        {
                          restaurantsList.response.find(
                            (restaurant) =>
                              restaurant.id ===
                              discountCode.applicableRestaurantId
                          )?.label
                        }
                      </Label>
                    ) : (
                      <NoDataMessage>Aucun</NoDataMessage>
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <Row>
                      <Button
                        icon="edit"
                        color="blue"
                        onClick={() => setUpdateId(discountCode.id)}
                      />
                    </Row>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </WithLoader>

      <Pagination
        className="pagination"
        boundaryRange={0}
        onPageChange={(e, { activePage }) => handlePageChange(activePage)}
        activePage={currentPage}
        siblingRange={1}
        totalPages={maxPage}
        pointing
        secondary
        fluid
      />

      {isModalOpened && (
        <DiscountCodeModal
          open={isModalOpened}
          updateId={discountCodeId}
          onClose={() => {
            closeModal();
            setUpdateId(null);
          }}
          closeModal={({ withReload }) => {
            closeModal();
            setUpdateId(null);
            if (withReload) {
              handlePageChange(currentPage);
            }
          }}
        />
      )}
    </div>
  );
};
export default DiscountCodesList;
